import React, { FC, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState, IUser, UserRank } from 'storage/auth/models';
import { Avatar, Button, Card, CardContent, Modal, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { clearFloatingXP, getLeaderboard, getUserStats } from 'storage/auth/duck';
import theme from 'theme';
import SpeedIcon from '@mui/icons-material/Speed';
import CloseIcon from '@mui/icons-material/Close';
import { FaCrown } from 'react-icons/fa';
import './UserStats.css'
import { IAppState } from 'storage/app/models';
import { trackEvent } from 'storage/tracking/duck';
import { isTablet } from 'utils/utils';


interface LeaderboardItem {
  rank: number;
  name: string;
  nameTag: string;
  value: number;
  highlighted?: boolean;
}

/*const regionNameTagMap: { [key: string]: string } = {
  'Central': 'CE',
  'Mississippi Valley': 'MV',
  'North Central': 'NC',
  'Northeast': 'NE',
  'South Central': 'SC',
  'Southeast': 'SE',
  'Western': 'WE',
}*/

const processUserLeaderboard = (leaderboard: UserRank[], currentUser: IUser): LeaderboardItem[] => {
  return leaderboard.map((user) => ({
    rank: user.rank,
    name: `${user.user_first_name} ${user.user_last_name}`,
    nameTag: `${user.user_first_name[0]}${user.user_last_name[0]}`,
    value: user.value,
    highlighted: currentUser.email === user.user_email
  }))
}

/*const processRegionLeaderboard = (leaderboard: RegionRank[], currentUser: IUser): LeaderboardItem[] => {
  return leaderboard.map((region) => ({
    rank: region.rank,
    name: region.name,
    nameTag: regionNameTagMap[region.name],
    value: region.value,
    highlighted: currentUser.default_region?.includes(region.name)
  }))
}*/


interface TopRankedUserProps {
  leaderboard: LeaderboardItem;
  valueParser?: (value: number) => string;
}

function TopRankedUser(props: TopRankedUserProps) {
  const { valueParser } = props;
  const { rank, name, nameTag, value, highlighted } = props.leaderboard;
  const isFirst = rank === 1;

  return (
    <Stack
      position='relative'
      alignItems='center'
    >
      {isFirst ? <FaCrown
        id='crown-monthly-leaderboard'
        style={{
          position: 'absolute',
          color: 'rgb(233, 196, 6)',
          top: '-2rem',
          width: '2.5rem',
          height: '2.5rem',
          stroke: '#11224B',
          overflow: 'visible',
          strokeWidth: '32px',
          zIndex: 1000
        }}
      /> : <Typography variant="h6">#{rank}</Typography>}
      <Tooltip title={`${name}`} placement="top">
        <Avatar
          alt={nameTag}
          sx={{
            width: isFirst ? '5rem' : '4rem',
            height: isFirst ? '5rem' : '4rem',
            textTransform: "uppercase",
            color: highlighted ? theme.palette.background.default : theme.palette.primary.main,
            backgroundColor: highlighted ? theme.palette.primary.main : theme.palette.background.default,
            border: '3px solid',
            borderColor: theme.palette.background.highlight,
            boxShadow: isFirst ? `0px 2px 50px 0px ${theme.palette.background.highlight}` : 'none',
            fontSize: isFirst ? '1.75rem' : "1.5rem",
            pt: '0.1rem',
            mb: '0.25rem'
          }}
        >
          {nameTag}
        </Avatar>
      </Tooltip>
      <Typography variant="h6">{valueParser ? valueParser(value) : value}</Typography>
    </Stack>
  );
}


interface TabPanelProps {
  leaderboard?: LeaderboardItem[];
  index: number;
  value: number;
  valueParser?: (value: number) => string;
}

function LeaderboardTabPanel(props: TabPanelProps) {
  const { leaderboard, value, index, valueParser, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`leaderboard-tabpanel-${index}`}
      {...other}
    >
      {value === index && leaderboard && (
        <Box sx={{ p: 3 }}>
          <Stack direction="row" justifyContent='center' spacing={4} pt='2rem' mb='1rem'>
            {leaderboard.length >= 2 && <TopRankedUser leaderboard={leaderboard[1]} valueParser={valueParser} />}
            {leaderboard.length >= 1 && <TopRankedUser leaderboard={leaderboard[0]} valueParser={valueParser} />}
            {leaderboard.length >= 3 && <TopRankedUser leaderboard={leaderboard[2]} valueParser={valueParser} />}
          </Stack>
          <Stack spacing={1} overflow='auto' maxHeight='40svh' paddingRight='0.5rem'>
            {leaderboard.slice(3).map((user) => (
              <Stack key={user.rank} direction="row" justifyContent='center' alignItems='center' spacing={2}>
                <Typography variant="h6" width='1rem' textAlign='center' flexShrink={0}>#{user.rank}</Typography>
                <Avatar
                  alt={user.nameTag}
                  sx={{
                    width: '2.5rem',
                    height: '2.5rem',
                    textTransform: "uppercase",
                    color: user.highlighted ? theme.palette.background.default : theme.palette.primary.main,
                    backgroundColor: user.highlighted ? theme.palette.primary.main : theme.palette.background.default,
                    fontSize: "1rem",
                    pt: '0.1rem',
                    flexShrink: 0,
                  }}
                >
                  {user.nameTag}
                </Avatar>
                <Typography variant="h6" flexGrow={1}>{user.name}</Typography>
                <Typography variant="h6">{valueParser ? valueParser(user.value) : user.value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
    </div>
  );
}

interface UserStatsModalProps {
  open: boolean;
  stats: IUser['stats'];
  leaderboards: IAuthState['leaderboards'];
  closeModal: () => void;
  user: IUser;
}

const UserStatsModal: FC<UserStatsModalProps> = ({ open, leaderboards, closeModal, user }) => {
  const dispatch = useDispatch();
  const [activeUserTab, setActiveUserTab] = React.useState(0);
  /*const [activeRegionTab, setActiveRegionTab] = React.useState(0);*/
  const [xpLeaderboard, setXpLeaderboard] = useState<LeaderboardItem[]>();
  const [notesLeaderboard, setNotesLeaderboard] = useState<LeaderboardItem[]>()
  const [escalatedLeaderboard, setEscalatedLeaderboard] = useState<LeaderboardItem[]>()
  const [workingOnItLeaderboard, setWorkingOnItLeaderboard] = useState<LeaderboardItem[]>()
  /*const [region15DaysLeaderboard, setRegion15Days] = useState<LeaderboardItem[]>()*/
  /*const [region90DaysLeaderboard, setRegion90Days] = useState<LeaderboardItem[]>()*/
  /*const parsePercentage = (value: number) => `${(value * 100).toPrecision(3)}%`*/

  useEffect(() => {
    if (open) {
      dispatch(getUserStats())
      dispatch(getLeaderboard())
    }
  }, [open])

  useEffect(() => {
    if (leaderboards) {
      setXpLeaderboard(processUserLeaderboard(leaderboards.xp, user))
      setNotesLeaderboard(processUserLeaderboard(leaderboards.notes, user))
      setEscalatedLeaderboard(processUserLeaderboard(leaderboards.escalated, user))
      setWorkingOnItLeaderboard(processUserLeaderboard(leaderboards.workingOnIt, user))
      /*setRegion15Days(processRegionLeaderboard(leaderboards.region15Days, user))*/
      /*setRegion90Days(processRegionLeaderboard(leaderboards.region90Days, user))*/
    }
  }, [leaderboards])

  const handleSetActiveUserTab = (_: React.SyntheticEvent, tab: number) => {
    setActiveUserTab(tab);
  };

  /*const handleSetActiveRegionTab = (_: React.SyntheticEvent, tab: number) => {
    setActiveRegionTab(tab);
  };*/

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '32rem',
      }}>
        <Button
          variant='text'
          size='small'
          onClick={closeModal}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent>
          <Stack direction="row" gap={4}>
            <Stack minWidth="26rem">
              <Typography variant="h4" >
                User Leaderboard
              </Typography>
              <Tabs variant="fullWidth" value={activeUserTab} onChange={handleSetActiveUserTab} sx={{ w: '100%' }}>
                <Tab label="XP" sx={{ color: 'rgba(255, 255, 255, .5)' }} />
                <Tab label="Notes" sx={{ color: 'rgba(255, 255, 255, .5)' }} />
                <Tab label="Escalated" sx={{ color: 'rgba(255, 255, 255, .5)' }} />
                <Tab label="Follow up" sx={{ color: 'rgba(255, 255, 255, .5)', whiteSpace: 'nowrap', paddingX: '2rem' }} />
              </Tabs>
              <LeaderboardTabPanel value={activeUserTab} index={0} leaderboard={xpLeaderboard} />
              <LeaderboardTabPanel value={activeUserTab} index={1} leaderboard={notesLeaderboard} />
              <LeaderboardTabPanel value={activeUserTab} index={2} leaderboard={escalatedLeaderboard} />
              <LeaderboardTabPanel value={activeUserTab} index={3} leaderboard={workingOnItLeaderboard} />
            </Stack>
            {/*
            <Divider orientation="vertical" flexItem />
            <Stack minWidth="26rem">
              <Typography variant="h4" >
                Regional Leaderboard
              </Typography>
              <Tabs variant="fullWidth" value={activeRegionTab} onChange={handleSetActiveRegionTab} sx={{ w: '100%' }}>
                <Tab label="15 days" sx={{ color: 'rgba(255, 255, 255, .5)' }} />
                <Tab label="90 days" sx={{ color: 'rgba(255, 255, 255, .5)' }} />
              </Tabs>
              <LeaderboardTabPanel value={activeRegionTab} index={0} leaderboard={region15DaysLeaderboard} valueParser={parsePercentage} />
              <LeaderboardTabPanel value={activeRegionTab} index={1} leaderboard={region90DaysLeaderboard} valueParser={parsePercentage} />
            </Stack>
             */}
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

interface FloatingExperienceProps {
  direction: 'down' | 'up';
  experience: number;
}

const FloatingExperience: FC<FloatingExperienceProps> = ({ direction, experience }) => {
  const [animating, setAnimating] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setAnimating(true), 10);
  }, [])

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 100,
        right: '0',
        top: direction === 'up' ? '-2.25rem' : 'unset',
        bottom: direction === 'down' ? '-2.25rem' : 'unset',
        opacity: animating ? 0 : 11,
        transform: animating && direction === 'up' ?
          'translate( 33%, -2rem)' :
          animating && direction === 'down' ?
            'translate( 33%, 2rem)' :
            'translate(33%, 0)'
        ,
        transition: 'all 2s ease-in-out, opacity 1s ease-in-out 1s',
        // text border
        textShadow: '2px 0 4px #000, -2px 0 4px #000, 0 2px 4px #000, 0 -2px 4px #000, 1px 1px 4px #000, -1px -1px 4px #000, 1px -1px 4px #000, -1px 1px 4px #000',
      }}
    >
      <Typography variant="h6">{experience > 0 ? '+' : '-'} {Math.abs(experience)} XP</Typography>
    </Box>
  )
}

const UserStats: FC = () => {
  const dispatch = useDispatch();
  const { user, newXP, leaderboards } = useSelector<IStore, IAuthState>(store => store.auth);
  const { isFullscreen } = useSelector<IStore, IAppState>(store => store.app);
  const [floatingExperiences, setFloatingExperiences] = useState<{ id: string, value: number }[]>([]);
  const floatingExperiencesRef = useRef<{ id: string, value: number }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const ipad = isTablet();
  const width = ipad ? '12rem' : '20rem'

  useEffect(() => {
    dispatch(getUserStats())
    // Get user stats every minute
    const interval = setInterval(() => {
      dispatch(getUserStats())
      dispatch(getLeaderboard())
    }, 63000)
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if (newXP) {
      const id = Math.random().toString()
      const newFloatingExperiences = [...floatingExperiencesRef.current, { id, value: newXP }];
      setFloatingExperiences(newFloatingExperiences);
      floatingExperiencesRef.current = newFloatingExperiences;
      dispatch(clearFloatingXP())
      setTimeout(() => {
        const filteredFloatingExperiences = floatingExperiencesRef.current.filter((experience) => experience.id !== id);
        setFloatingExperiences(filteredFloatingExperiences);
        floatingExperiencesRef.current = filteredFloatingExperiences;
      }, 2000)
    }
  }, [newXP])

  const handleOpenUserStatsModal = (e: React.MouseEvent<HTMLButtonElement | SVGElement, MouseEvent>) => {
    setIsModalOpen(true)
    if (e.detail === 1) {
      dispatch(trackEvent({ namespace: 'View leaderboard', predicate: 'User XP bar' }))
    };
  }

  const handleCloseUserStatsModal = () => {
    setIsModalOpen(false)
  }

  return (user ?
    <Box
      id="user-stats"
      sx={{
        position: 'absolute',
        zIndex: 1000,
        left: isFullscreen ? 'unset' : '1rem',
        right: isFullscreen ? '4.5rem' : 'unset',
        bottom: isFullscreen ? 'unset' : ipad ? '0.5rem' : '1rem',
        top: isFullscreen ? '1rem' : 'unset',
        backgroundColor: 'background.paper',
        opacity: 0.9,
        p: '0.5rem',
        pr: '1rem',
        width: width,
      }}
    >
      <Stack position='relative' flexDirection='row' alignItems='center' >
        <Button sx={{ marginRight: '0.5rem', minWidth: '2rem' }} onClick={handleOpenUserStatsModal}>
          <SpeedIcon />
        </Button>
        <Box width='100%'>
          <Stack flexDirection='row' justifyContent='space-between' marginBottom='0.25rem'>
            <Typography fontSize="small">Level {user.stats?.level}</Typography>
            <Stack flexDirection='row'>
              <Typography id='current-xp' fontSize="small" sx={{ '--num': user.stats ? user.stats?.experience : 0 }}></Typography>
              <Typography fontSize="small" sx={{ ml: '0.25rem' }}>/ {user.stats?.next_level}</Typography>
            </Stack>
          </Stack>
          <Box >
            <Box
              sx={{
                height: '0.5rem',
                width: '100%',
                backgroundColor: 'grey.500',
              }}
            >
              <Box
                sx={{
                  height: '0.5rem',
                  width: `${user?.stats ? user.stats.experience / user.stats.next_level * 100 : 0}%`,
                  backgroundColor: 'primary.main',
                  transition: 'width 1s ease-in-out'
                }}
              />
            </Box>
          </Box>
        </Box>
        {floatingExperiences.map((experience) => (
          <FloatingExperience key={experience.id} direction={isFullscreen ? 'down' : 'up'} experience={experience.value} />
        ))}
        {user.stats?.xp_rank_monthly === 1 && user.stats?.xp_count_monthly > 0 &&
          <FaCrown
            onClick={handleOpenUserStatsModal}
            id='crown'
            style={{
              position: 'absolute',
              color: 'rgb(233, 196, 6)',
              top: '-2.3rem',
              right: '-2rem',
              width: '2.5rem',
              height: '2.5rem',
              transform: 'rotate(25deg)',
              stroke: '#11224B',
              overflow: 'visible',
              strokeWidth: '32px',
              cursor: 'pointer'
            }}
          />
        }
      </Stack>
      <UserStatsModal open={isModalOpen} stats={user.stats} leaderboards={leaderboards} closeModal={handleCloseUserStatsModal} user={user} />
    </Box> :
    null
  );
};

export default UserStats;
