/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'lib/axios/api';
import { AggregationLevel, AppData, AppFilters, AppPanelFilters, Attachment, FrequentServiceProvider, HeatmapCell, Workorder } from 'storage/app/models';
import { filterToParams } from 'utils/utils';
import { AxiosResponse } from "axios";
import { StringItem } from 'components/FilterBar/FilterMenu';
import { delay, put } from 'redux-saga/effects';
import { refreshToken } from 'storage/auth/duck';
import { Action } from 'redux';
import { trackEvent } from 'storage/tracking/duck';

/**
 * Get application metadata.
 */
export const getMetadataService = async () => {
  const response = await api.get('/info/');
  return { ...response.data };
};

/**
 * Get FeatureCollections of the region boundaries and market boundaries.
 */
export const getGeojsonService = async () => {
  const regionsResponse = await api.get('/api/v1/geometry/region/');
  const marketsResponse = await api.get('/api/v1/geometry/market/');
  return {
    regions: regionsResponse.data,
    markets: marketsResponse.data
  };
};

/**
 * Get work orders, regions, markets, and clubs filtered and aggregated
 * based on the current active filters.
 */
export const getAggregateDataService = async (filters: AppFilters, aggregationLevel: AggregationLevel): Promise<AppData | undefined> => {
  let response: AxiosResponse | undefined;
  const params = filterToParams(filters)
  if (aggregationLevel === AggregationLevel.REGION) {
    response = await api.get('/api/v1/aggregate-info/region/', { params: params });
  } else if (aggregationLevel === AggregationLevel.MARKET) {
    response = await api.get('/api/v1/aggregate-info/market/', { params: params });
  } else if (aggregationLevel === AggregationLevel.CLUB) {
    response = await api.get('/api/v1/aggregate-info/club/', { params: params });
  }

  if (response) {
    return response.data;
  } else {
    return;
  }
};

export interface WorkorderResponse {
  workorders: Workorder[],
  totalWorkorders: number,
  frequentServiceProviders: FrequentServiceProvider[]
}

/**
 * Get all the work orders that match the current active filters.
 */
export const getWorkorderDataService = async (filters: AppFilters, panelFilters: AppPanelFilters, sortParam: string[], page: number, pageSize: number): Promise<WorkorderResponse> => {
  let params: any = {
    sort: sortParam,
    page: page,
    page_size: pageSize
  };
  params = filterToParams(filters, params)
  params = filterToParams(panelFilters, params);
  const response = await api.get('/api/v1/workorders/', { params: params });
  return {
    workorders: response.data.results,
    totalWorkorders: response.data.count,
    frequentServiceProviders: response.data.frequent_service_providers?.map((provider: any) => ({
      name: provider.provider_name,
      count: provider.count,
      percentage: provider.percentage
    })) || []
  };
};


/**
 * Get CSV file with all the work orders that match the current active filters.
 */
export const getWorkordersCSVService = async (filters: AppFilters, panelFilters: AppPanelFilters, sortParam: string[]): Promise<any> => {
  let params: any = {
    sort: sortParam,
  };
  params = filterToParams(filters, params)
  params = filterToParams(panelFilters, params);
  const response = await api.get('/api/v1/export/workorders/csv', { params: params });
  return response;
};

/**
 * Get heatmap data.
 */
export const getHeatmapDataService = async (filters: AppFilters) => {
  const params = filterToParams(filters)
  if (params['importance']) {
    delete params['importance']
  }
  if (params['urgency']) {
    delete params['urgency']
  }
  const response = await api.get('/api/v1/heatmap/', { params: params });
  if (response.data.length === 0) {
    let urgencyGroup = 1;
    const emptyData: HeatmapCell[] = [];
    Array.from(Array(10).keys()).forEach(() => {
      let importanceGroup = 1;
      Array.from(Array(10).keys()).forEach(() => {
        emptyData.push({
          importanceGroup: importanceGroup,
          urgencyGroup: urgencyGroup,
          count: 0,
          mean: 0,
          median: 0,
        });
        importanceGroup++;
      })
      urgencyGroup++;
    })
    return emptyData;
  } else {
    return response.data;
  }
};

/**
 * Set the "Working on it" status of a work order by a user.
 */
export const toggleWorkingOnItService = async (workorderId: number) => {
  const response = await api.put(`/api/v1/workorders/status/${workorderId}/`);
  return response.data;
};

/**
 * Post batch update workorder workingOnIt
 */
export const batchUpdateWorkingOnItService = async (workorderIds: number[], workingOnIt: boolean) => {
  const response = await api.put('/api/v1/workorders/status/', {
    workorders: workorderIds,
    value: workingOnIt
  });
  return response.data;
}

/**
 * Set the "Escalated" status of a work order by a user.
 */
export const toggleEscalatedService = async (workorderId: number) => {
  const response = await api.put(`/api/v1/workorders/escalated/${workorderId}/`);
  return response.data;
};

/**
 * Post batch update escalated workingOnIt
 */
export const batchUpdateEscalatedService = async (workorderIds: number[], escalated: boolean) => {
  const response = await api.put('/api/v1/workorders/escalated/', {
    workorders: workorderIds,
    value: escalated
  });
  return response.data;
}

/**
 * Take ownership of a work order by ID.
 */
export const takeOwnershipService = async (workorderId: number) => {
  const response = await api.put(`/api/v1/workorders/take-over/${workorderId}/`);
  return response.data;
};

/**
 * Set the "Bot shut off" status of a work order by a user.
 */
export const updateBotShutOffService = async (workorderId: number) => {
  const response = await api.put(`/api/v1/workorders/bot-shut-off/${workorderId}/`);
  return response.status === 204;
};

/**
 * Post batch update botShutOff workingOnIt
 */
export const batchUpdateBotShutOffService = async (workorderIds: number[], botShutOff: boolean) => {
  const response = await api.put('/api/v1/workorders/bot-shut-off/', {
    workorders: workorderIds,
    value: botShutOff
  });
  return response.data;
}

/**
 * Get scoreboard data.
 */
export const getScoreboardDataService = async () => {
  const response = await api.get('/api/v1/scoreboard/');
  return response.data;
};

/**
 * Get workorder data.
 */
export const getWorkorderService = async (id: Workorder["id"], filters?: AppFilters) => {
  let params: any = {};
  if (filters) {
    params = filterToParams(filters)
  }
  const response = await api.get(`/api/v1/workorders/${id}/`, { params: params });
  return response.data;
};


/**
 * Get trade options.
 */
export const getTradeOptionsService = async () => {
  const response = await api.get(`/api/v1/workorders/trades/`);
  const tradeOptions = response.data.trades.map((trade: string) => ({
    name: trade,
    value: trade
  })) as StringItem[]
  const groupedTradeOptions = response.data.trade_groups.map((trade: string) => ({
    name: trade,
    value: trade
  })) as StringItem[]
  return { tradeOptions, groupedTradeOptions }
};


/**
 * Get provider options.
 */
export const getProviderOptionsService = async () => {
  const response = await api.get(`/api/v1/workorders/providers/`);
  const providers = response.data.map((provider: string) => ({
    name: provider,
    value: provider
  }))
  return providers as StringItem[];
};

/**
 * Get categories options.
 */
export const getCategoryOptionsService = async () => {
  const response = await api.get(`/api/v1/workorders/categories/`);
  const categories = response.data.map((category: string) => ({
    name: category,
    value: category
  }))
  return categories as StringItem[];
};

/**
 * Get equipment type options.
 */
export const getEquipmentTypeOptionsService = async () => {
  const response = await api.get(`/api/v1/workorders/equipments/`);
  const equipmentTypes = response.data
    .map((equipment: string) => ({
      name: equipment !== null ? equipment : 'None',
      value: equipment
    }))
  return equipmentTypes as StringItem[];
};

/**
 * Get extended status type options.
 */
export const getExtendedStatusOptionsService = async () => {
  const response = await api.get(`/api/v1/workorders/extended-status/`);
  const extendedStatus = response.data
    .map((extStatus: string) => ({
      name: extStatus ? extStatus : 'None',
      value: extStatus
    }))
  return extendedStatus as StringItem[];
};


/**
 * Get problem description options.
 */
export const getProblemDescriptionOptionsService = async () => {
  const response = await api.get(`/api/v1/workorders/problem-description/`);
  return response.data as StringItem[];
};


/**
 * Get nested clubs.
 */
export const getLocationsService = async () => {
  const response = await api.get(`/api/v1/workorders/locations/`);
  return response.data as StringItem[];
};


/**
 * Get workorder attachments
 */
export const getAttachmentsService = async (id: Workorder["id"]) => {
  const response = await api.get(`/api/v1/workorders/attachments/${id}/`);
  return response.data as Attachment[];
};

/**
 * Get BSI config parameters data
 */
export const getBsiConfigParamsService = async () => {
  const response = await api.get(`/bsi/`);
  return response.data;
};

/**
 * Gracefully handle API errors.
 */
export interface APIError {
  message: string
  response?: {
    data?: {
      code: string
      detail: string
    }
  }
}

export function* errorHandler(
  error: APIError,
  callback: (payload: { message: string }) => void,
  retry?: () => Action<any>,
) {
  const invalidToken = error?.response?.data?.code === 'token_not_valid'
  if (invalidToken) {
    yield put(refreshToken())
    yield delay(500)
    yield put(trackEvent({
      namespace: 'API Error',
      predicate: 'Refreshing invalid token',
      value: retry?.name,
      payload: error?.response?.data
    }))
    if (retry) {
      yield delay(500)
      yield put(retry())
    }

  }
  if (error?.response?.data?.detail) {
    yield callback({ message: error.response?.data?.detail })
  } else {
    yield callback({ message: error.message })
  }
}